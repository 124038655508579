import { render, staticRenderFns } from "./index.vue?vue&type=template&id=05ce1581&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=05ce1581&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ce1581",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\user\\Desktop\\Bunkerchain\\portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05ce1581')) {
      api.createRecord('05ce1581', component.options)
    } else {
      api.reload('05ce1581', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=05ce1581&scoped=true", function () {
      api.rerender('05ce1581', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/components/FontSizeSelector/index.vue"
export default component.exports